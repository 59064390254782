// ============================================================
// ATTENTION & COMMON RULE!!
// 関数を実装のみ（処理の実行は下部で実行する）
// 関数名には振る舞いを表す英語プラスFuncを付ける
// ============================================================

// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
// -------------------------------------------------------------

function mq(device) {
  if (window.matchMedia("(max-width:768px)").matches && device === "sp") {
    return true;
  } else if (
    window.matchMedia("(min-width:769px)").matches &&
    device === "pc"
  ) {
    return true;
  }
  return false;
}
// FUNCTIONS

function lazyLoadVideo() {
  var videoPc = document.getElementById("videoPc");

  var xhr = new XMLHttpRequest();
  xhr.open("GET", "../img/pc.mp4", true);
  xhr.responseType = "blob";
  console.log(xhr);
  xhr.onload = function(e) {
    if (this.status === 200) {
      console.log("videoPc ロード完了");
      // ロード完了
      var blob = this.response;
      var src = (window.webkitURL || window.URL).createObjectURL(blob);
    }
    console.log("videoPc loaded!!!");
    videoPc.src = "../img/pc.mp4";
    videoPc.play();
  };
  xhr.send();
}

/**
 * Get the URL parameter value
 *
 * @param  name {string} パラメータのキー文字列
 * @return  url {url} 対象のURL文字列（任意）
 */
function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function topPageFunc() {
  if (getParam("send") === "complete") {
    $("body")
      .addClass("modal-complete")
      .addClass("show-modal");
  } else if (getParam("send") === "failed") {
    $("body")
      .addClass("modal-failed")
      .addClass("show-modal");
  }
  $(".modal-close").click(function() {
    location.href = window.location.origin;
  });

  if (mq("sp")) {
    var topSwiper = new Swiper("#sliderWrapFirst", {
      effect: "fade",
      speed: 3000,
      pagination: {
        el: ".swiper-pagination",
      },
      autoplay: {
        delay: 4000,
      },
      loop: true,
    });
  } else {
    // lazyLoadVideo();
    var topSwiper = new Swiper("#sliderWrapFirst", {
      effect: "fade",
      speed: 3000,
      pagination: {
        el: ".swiper-pagination",
      },
      autoplay: {
        delay: 4000,
      },
      loop: true,
    });
  }
}

function subPageFunc() {
  if ($("#salonPage").length) {
    $(".slider-for").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: ".slider-nav",
    });

    $(".slider-nav").slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: ".slider-for",
      centerPadding: "0px",
      dots: false,
      centerMode: true,
      focusOnSelect: true,
    });
  }
}
function interSectiionObserveFunc() {
  var observer = lozad(".lozad", {
    loaded: function(el) {
      // Custom implementation on a loaded element
      el.classList.add("loaded");
    },
  });
  // lazy loads elements with default selector as '.lozad'
  observer.observe({});
}

function formCheck() {
  //エラーを表示する関数（error クラスの p 要素を追加して表示）
  function show_error(message, this$) {
    text =
      this$
        .parent()
        .find("label")
        .text() + message;
    this$.parent().append("<p class='error'>" + text + "</p>");
  }

  //フォームが送信される際のイベントハンドラの設定
  $("#formPage1").submit(function() {
    //エラー表示の初期化
    $("p.error").remove();
    $("div").removeClass("error");
    var text = "";
    $("#errorDispaly").remove();

    //メールアドレスの検証
    var email = $.trim($("#email").val());
    if (
      email &&
      !/^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/gi.test(
        email
      )
    ) {
      $("#email").after(
        "<p class='error'>メールアドレスの形式が異なります</p>"
      );
    }
    //確認用メールアドレスの検証
    // var email_check =  $.trim($("#email_check").val());
    // if(email_check && email_check != $.trim($("input[name="+$("#email_check").attr("name").replace(/^(.+)_check$/, "$1")+"]").val())){
    //   show_error("が異なります", $("#email_check"));
    // }
    //電話番号の検証
    var tel = $.trim($("#tel").val());
    if (
      tel &&
      !/^\(?\d{2,5}\)?[-(\.\s]{0,2}\d{1,4}[-)\.\s]{0,2}\d{3,4}$/gi.test(tel)
    ) {
      $("#tel").after(
        "<p class='error'>電話番号の形式が異なります（半角英数字でご入力ください）</p>"
      );
    }

    //カナの検証
    var kana = $.trim($("#kana").val());
    if (kana && !kana.match(/^[ァ-ヶー　]+$/)) {
      $("#kana").after(
        "<p class='error'>全角カタカナではない文字が含まれています</p>"
      );
    }

    //1行テキスト入力フォームとテキストエリアの検証
    $(":text,textarea")
      .filter(".validate")
      .each(function() {
        //必須項目の検証
        $(this)
          .filter(".required")
          .each(function() {
            if ($(this).val() == "") {
              show_error(" は必須項目です", $(this));
            }
          });
        //文字数の検証
        $(this)
          .filter(".max30")
          .each(function() {
            if ($(this).val().length > 30) {
              show_error(" は30文字以内です", $(this));
            }
          });
        $(this)
          .filter(".max50")
          .each(function() {
            if ($(this).val().length > 50) {
              show_error(" は50文字以内です", $(this));
            }
          });
        $(this)
          .filter(".max100")
          .each(function() {
            if ($(this).val().length > 100) {
              show_error(" は100文字以内です", $(this));
            }
          });
        $(this)
          .filter(".max1000")
          .each(function() {
            if ($(this).val().length > 1000) {
              show_error(" は1000文字以内でお願いします", $(this));
            }
          });
      });

    var ancFlg01 = false;
    $(".bento-check").each(function() {
      console.log($(this).val());
      if ($(this).val() != "0") {
        ancFlg01 = true;
      }
    });
    if (!ancFlg01) {
      show_error("最低一商品選んでください", $(".take-error"));
    }
    //error クラスの追加の処理
    if ($("p.error").length > 0) {
      // $("p.error").parent().addClass("error");
      $("html,body").animate(
        { scrollTop: $("p.error:first").offset().top - 180 },
        "slow"
      );
      return false;
    }
  });
  $.datetimepicker.setLocale("ja");
  $("#kibounitiji").datetimepicker({
    step: 30,
  });

  //テキストエリアに入力された文字数を表示
  // $("textarea").on('keydown keyup change', function() {
  //   var count = $(this).val().length;
  //   $("#count").text(count);
  //   if(count > 1000) {
  //     $("#count").css({color: 'red', fontWeight: 'bold'});
  //   }else{
  //     $("#count").css({color: '#333', fontWeight: 'normal'});
  //   }
  // });
}

function formCheck2() {
  //エラーを表示する関数（error クラスの p 要素を追加して表示）
  function show_error(message, this$) {
    text =
      this$
        .parent()
        .find("label")
        .text() + message;
    this$.parent().append("<p class='error'>" + text + "</p>");
  }

  //フォームが送信される際のイベントハンドラの設定
  $("#formPage2").submit(function() {
    //エラー表示の初期化
    $("p.error").remove();
    $("div").removeClass("error");
    var text = "";
    $("#errorDispaly").remove();

    //メールアドレスの検証
    var email = $.trim($("#email").val());
    if (
      email &&
      !/^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/gi.test(
        email
      )
    ) {
      $("#email").after(
        "<p class='error'>メールアドレスの形式が異なります</p>"
      );
    }
    //確認用メールアドレスの検証
    // var email_check =  $.trim($("#email_check").val());
    // if(email_check && email_check != $.trim($("input[name="+$("#email_check").attr("name").replace(/^(.+)_check$/, "$1")+"]").val())){
    //   show_error("が異なります", $("#email_check"));
    // }
    //電話番号の検証
    var tel = $.trim($("#tel").val());
    if (
      tel &&
      !/^\(?\d{2,5}\)?[-(\.\s]{0,2}\d{1,4}[-)\.\s]{0,2}\d{3,4}$/gi.test(tel)
    ) {
      $("#tel").after(
        "<p class='error'>電話番号の形式が異なります（半角英数字でご入力ください）</p>"
      );
    }

    //カナの検証
    var kana = $.trim($("#kana").val());
    if (kana && !kana.match(/^[ァ-ヶー　]+$/)) {
      $("#kana").after(
        "<p class='error'>全角カタカナではない文字が含まれています</p>"
      );
    }

    //1行テキスト入力フォームとテキストエリアの検証
    $(":text,textarea")
      .filter(".validate")
      .each(function() {
        //必須項目の検証
        $(this)
          .filter(".required")
          .each(function() {
            if ($(this).val() == "") {
              show_error(" は必須項目です", $(this));
            }
          });
        //文字数の検証
        $(this)
          .filter(".max30")
          .each(function() {
            if ($(this).val().length > 30) {
              show_error(" は30文字以内です", $(this));
            }
          });
        $(this)
          .filter(".max50")
          .each(function() {
            if ($(this).val().length > 50) {
              show_error(" は50文字以内です", $(this));
            }
          });
        $(this)
          .filter(".max100")
          .each(function() {
            if ($(this).val().length > 100) {
              show_error(" は100文字以内です", $(this));
            }
          });
        $(this)
          .filter(".max1000")
          .each(function() {
            if ($(this).val().length > 1000) {
              show_error(" は1000文字以内でお願いします", $(this));
            }
          });
      });

    var ancFlg01 = false;
    $(".bento-check").each(function() {
      console.log($(this).val());
      if ($(this).val() != "0") {
        ancFlg01 = true;
      }
    });
    if (!ancFlg01) {
      show_error("最低一商品選んでください", $(".take-error"));
    }
    //error クラスの追加の処理
    if ($("p.error").length > 0) {
      // $("p.error").parent().addClass("error");
      $("html,body").animate(
        { scrollTop: $("p.error:first").offset().top - 180 },
        "slow"
      );
      return false;
    }
  });
  $.datetimepicker.setLocale("ja");
  $("#kibounitiji").datetimepicker({
    step: 30,
  });

  //テキストエリアに入力された文字数を表示
  // $("textarea").on('keydown keyup change', function() {
  //   var count = $(this).val().length;
  //   $("#count").text(count);
  //   if(count > 1000) {
  //     $("#count").css({color: 'red', fontWeight: 'bold'});
  //   }else{
  //     $("#count").css({color: '#333', fontWeight: 'normal'});
  //   }
  // });
}

// ============================================================
// ATTENTION & COMMON RULE!!
// まとめて関数実行（※必要に応じて条件分岐を入れる）
// ページ個別に処理をする場合は「ページ固有のID名.lengthで処理を分岐」
// ============================================================

// --- ページの全データを読み込み後 ----------------------------------
$(window).on("load", function() {});

// --- リサイズが走った場合 ----------------------------------------
$(window).on("resize", function() {});

// --- DOM生成後 -----------------------------------------------
$(function() {
  console.log("script.js");
  interSectiionObserveFunc();
  if ($("#topPage").length) {
    topPageFunc();
  } else {
    subPageFunc();
  }
  // SMOOTH SCROOL ATTRIBUTE NAME
  $("a.goto-top").click(function() {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;
    $("html, body").animate({ scrollTop: position }, speed, "linear");
    return false;
  });

  // SMOOTH SCROOL FOR CLASS NAME
  $("a.scroll").click(function() {
    var $pos = $($(this).attr("href")).offset().top - 130;
    $("html,body").animate({ scrollTop: $pos }, 500, "linear");
    $("body").removeClass("menu-show");
    return false;
  });

  // MENU
  $("#menuTrigger").click(function() {
    if ($("body").hasClass("menu-show")) {
      $("body").removeClass("menu-show");
    } else {
      $("body").addClass("menu-show");
    }
  });
  // PC or SPサンプル
  if (mq("sp")) {
    console.log("Mobile");
  } else {
    console.log("Desktop");
  }
  if ($("#formPage1").length) {
    formCheck();
  }
  if ($("#formPage2").length) {
    formCheck2();
  }
});

// SCROLL AND TOCH START ANIMATION
$(window).on("touchstart scroll", function() {});
